import type {
  ActionType,
  ConditionType,
  IAction,
  ICondition,
  IContent,
  ISendForm,
  ISmartDelay,
  IStartFlow,
  ITrigger,
  RandomizeType,
  SendFormType,
  SmartDelayType,
  StartFlowType,
} from '@/models/diagram.d';
import { ContentType, TriggerType } from '@/models/diagram.d';
import { v4 as uuidv4 } from 'uuid';

export const ContentFactory = ({
  type,
  initialValues,
}: {
  type:
    | ActionType
    | ContentType
    | TriggerType
    | StartFlowType
    | SendFormType
    | SmartDelayType
    | ConditionType
    | RandomizeType;
  initialValues?: any;
}) => {
  const content: ITrigger | IAction | IContent | IStartFlow | ISendForm | ISmartDelay | ICondition =
    {
      id: uuidv4(),
      type,
      data: {},
    };
  switch (type) {
    case TriggerType.WHATSAPP_LINK:
      content.data = {
        label: '',
        phone_number: '',
        message: '',
      };
      break;
    case TriggerType.KEYWORD:
      content.data = {
        condition: 'is',
        keywords: [],
      };
      break;
    case TriggerType.WEBHOOK:
      content.data = {
        attributes: [],
      };
      break;
    case 'condition':
      const action_type = uuidv4().split('-')[0];
      content.data = {
        multi_conditions: [
          {
            action_type: action_type,
            conditions: [],
          },
        ],
        response: [
          {
            type: action_type,
            id: uuidv4(),
          },
          {
            type: 'not-matched',
            id: uuidv4(),
          },
        ],
      };
      break;
    case 'webhook':
      content.data = {
        url: '',
        method: 'POST',
        headers: [],
        params: [],
        attributes: [],
        response: [
          {
            type: 'res-success',
            id: uuidv4(),
          },
          {
            type: 'res-fail',
            id: uuidv4(),
          },
        ],
      };
      break;
    case TriggerType.EVENT_TRIGGER:
      content.data = {
        vendor: '',
        event: '',
      };
      break;
    case 'add-tag':
      content.data = {
        tags: [],
      };
      break;
    case 'add-flow':
      content.data = {
        flow: {},
      };
      break;
    case 'send-form':
      content.data = {
        message: 'Please fill up the following form:\n{{formLink}}',
        form: '',
        response: [
          {
            type: 'after-submit',
            id: uuidv4(),
          },
        ],
      };
      break;
    case 'remove-tag':
      content.data = {
        tags: [],
      };
      break;
    case 'add-assignee':
      content.data = {
        assignee: [],
      };
      break;
    case 'remove-assignee': // @todo: not tested
      content.data = {
        assignee: null,
      };
      break;
    case 'add-collaborator':
      content.data = {
        collaborators: [],
      };
      break;
    case ContentType.TEXT:
      content.data = {
        text: '',
      };
      break;
    case 'send-message':
      content.data = {
        message: '',
        phone_no: '',
      };
      break;
    case 'send-waba-template':
      content.data = {
        fbid: '',
        buttons: [],
        components: [],
        phone_no: '',
      };
      break;
    case ContentType.IMAGE:
      content.data = {
        filename: '',
        url: '',
      };
      break;
    case ContentType.DOCUMENT:
      content.data = {
        filename: '',
        url: '',
      };
      break;
    case ContentType.VIDEO:
      content.data = {
        filename: '',
        url: '',
      };
      break;
    case ContentType.AUDIO:
      content.data = {
        filename: '',
        url: '',
      };
      break;
    case 'add-attribute':
      content.data = {
        attributes: [],
      };
      break;
    case ContentType.TEMPLATE:
      content.data = {
        fbid: '',
        buttons: [],
        components: [],
      };
      break;
    case 'assign-inbox-tab':
      content.data = {
        tab: [],
      };
      break;
    case 'close-conversation':
      content.data = {};
      break;
    case 'smart-delay':
      content.data = {
        duration: '1',
        duration_type: 'hour',
        response: [
          {
            type: 'answered',
            id: uuidv4(),
          },
          {
            type: 'not-answered',
            id: uuidv4(),
          },
        ],
      };
      break;
    case 'wait-reply':
      content.data = {
        duration: '1',
        duration_type: 'hour',
        response: [
          {
            type: 'not-answered',
            id: uuidv4(),
          },
        ],
      };
      break;
    case 'round-robin-steps':
      content.data = {
        steps: [
          {
            id: uuidv4(),
            type: null,
          },
          {
            id: uuidv4(),
            type: null,
          },
        ],
      };
      break;
    case 'randomizer':
      const actionType1 = uuidv4().split('-')[0];
      const actionType2 = uuidv4().split('-')[0];
      content.data = {
        chances: [
          {
            action_type: actionType1,
            probability: 50,
          },
          {
            action_type: actionType2,
            probability: 50,
          },
        ],
        response: [
          {
            type: actionType1,
            id: uuidv4(),
          },
          {
            type: actionType2,
            id: uuidv4(),
          },
        ],
      };
      break;
    default:
      break;
  }
  content.data = {
    ...content.data,
    ...initialValues,
  };
  return content;
};
