import ErrorBoundary from '@/components/ErrorBoundary';
import Footer from '@/components/Footer';
import RightContent from '@/components/RightContent';
import type { CurrentUser } from '@/declarations/user';
import { SettingOutlined } from '@ant-design/icons';
import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Button, Result } from 'antd';
import Cookies from 'js-cookie';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { Suspense, useEffect } from 'react';
import type { RunTimeLayoutConfig } from 'umi';
import { FormattedMessage, history, Link, useModel } from 'umi';
import { v4 as uuidv4 } from 'uuid';
import 'video-react/dist/video-react.css'; // import css
import AIAssistant from './components/AIAssistant';
import type { Channel } from './declarations/channel';
import type { Team } from './declarations/team';
import { getChannels } from './services/luluchat/channels';
import { getProfile } from './services/luluchat/users';
import 'video-react/dist/video-react.css'; // import css
import 'react-big-calendar/lib/css/react-big-calendar.css';

const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/login';
const autoLoginPath = '/user/auto-login';
const registerPath = '/user/register';
const excludedPaths = [loginPath, registerPath, autoLoginPath, '/vendor/shopify-oauth'];

const growthbook = new GrowthBook({
  enabled: !!GROWTHBOOK_CLIENT_KEY,
  apiHost: 'https://cdn.growthbook.io',
  clientKey: GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log('Viewed Experiment', {
      experimentId: experiment.key,
      variationId: result.key,
    });
  },
});

if (REACT_SENTRY_DSN) {
  Sentry.init({
    release: COMMIT_HASH,
    dsn: REACT_SENTRY_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      "NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD",
      'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
    ],
    beforeBreadcrumb(breadcrumb, hint: any) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event;
        if (target.innerText) {
          breadcrumb.message = target.innerText;
        }
      }
      return breadcrumb;
    },
  });
}

moment.locale('en');

const MoreProvider = ({ children, routes }: any) => {
  const newChildren = React.cloneElement(children, {
    ...children.props,
    routes,
  });
  const { initialState } = useModel('@@initialState');
  const currentUser = initialState?.currentUser;

  useEffect(() => {
    if (!_isEmpty(currentUser)) {
      growthbook.setAttributes({
        uuid: currentUser.uuid,
        planId: currentUser?.current_team?.limit?.billing?.plan?.id,
        planName: currentUser?.current_team?.limit?.billing?.plan?.name,
      });
      growthbook.loadFeatures();
      if (currentUser?.id) {
        Sentry.setUser({ id: `${currentUser.id}`, username: currentUser?.fullname });
      }
    }
  }, [currentUser]);

  return (
    <Suspense fallback={<PageLoading />}>
      <GrowthBookProvider key="gbp" growthbook={growthbook}>
        {newChildren}
        <AIAssistant />
      </GrowthBookProvider>
    </Suspense>
  );
};

export function rootContainer(container: any) {
  return React.createElement(MoreProvider, null, container);
}

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser: CurrentUser;
  currentChannel: Channel;
  channels: Channel[];
  currentTeam: Team;
  fetchUserChannels?: () => Promise<Channel[] | undefined>;
  fetchUserInfo?: () => Promise<CurrentUser | undefined>;
}> {
  localStorage.setItem('randomId', uuidv4()?.split('-')[0]);
  const fetchUserInfo = async () => {
    try {
      const msg = await getProfile();
      return msg.data;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };
  const fetchUserChannels = async () => {
    try {
      const msg = await getChannels();
      return msg.data || [];
    } catch (error) {
      console.error(error);
    }
    return [];
  };
  // 如果是登录页面，不执行
  if (!excludedPaths.includes(history.location.pathname)) {
    const currentUser = await fetchUserInfo();
    let channels = [] as Channel[];
    if (currentUser) {
      channels = await fetchUserChannels();
      const currentChannel: Channel = currentUser.current_channel;
      const currentTeam: Team = currentUser.current_team;
      if (currentChannel?.uuid) {
        localStorage.setItem('x-channel-id', currentChannel.uuid);
      }
      if (currentTeam?.uuid) {
        localStorage.setItem('x-team-id', currentTeam.uuid);
      }
      if (currentChannel?.info?.token) {
        Cookies.set('fb_access_token', currentChannel?.info?.token);
      }
      return {
        fetchUserInfo,
        fetchUserChannels,
        currentUser,
        currentChannel,
        currentTeam,
        channels,
        settings: {},
      };
    }
  }
  return {
    fetchUserChannels,
    fetchUserInfo,
    currentUser: {} as CurrentUser,
    currentChannel: {} as Channel,
    currentTeam: {} as Team,
    channels: [] as Channel[],
    settings: {},
  };
}
let hasAddedTopLine = false;
let firstTopLineMenuKey = '';
// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    defaultCollapsed: history?.location?.pathname.startsWith('/inbox'),
    breakpoint: false,
    links: [
      <Link key={2} to="/settings">
        <SettingOutlined />
        <span>
          <FormattedMessage id="menu.settings" />
        </span>
      </Link>,
    ],
    menuItemRender(item, defaultDom, menuProps) {
      if ((item?.key === '/forms' || item?.key === '/apps') && hasAddedTopLine === false) {
        hasAddedTopLine = true;
        firstTopLineMenuKey = item?.key;
      }
      return (
        <div
          style={firstTopLineMenuKey === item?.key ? { borderTop: '1px solid #eee' } : {}}
          onClick={() => {
            if (item?.itemPath === '/inbox' ||  menuProps?.isMobile) {
              if (menuProps && typeof menuProps?.onCollapse == 'function') {
                menuProps.onCollapse(true);
              }
            }
            if (item?.path) {
              history.push(item?.path);
            }
          }}
        >
          {defaultDom}
        </div>
      );
    },
    menuDataRender(menuData) {
      let _menuData = menuData;
      if (initialState?.currentChannel?.type !== 'waba') {
        _menuData = _menuData?.filter(({ path }) => path !== '/message-templates');
      }
      return _menuData;
    },
    menuHeaderRender: (logo, title, props) => {
      return props?.collapsed ? <img src="/images/svg/logo_icon.svg" /> : logo;
    },
    ErrorBoundary: ErrorBoundary,
    // 自定义 403 页面
    unAccessible: (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={[
          <Button key={2} type="primary" onClick={() => history.goBack()}>
            Go Back
          </Button>,
          <Button key={1} type="default" href="/dashboard">
            Go to Dashboard
          </Button>,
        ]}
      />
    ),
    ...initialState?.settings,
  };
};
