import type  { Message } from '@/declarations/channel';
import type { Table } from 'dexie';
import Dexie from 'dexie';

export interface Avatar {
  contact_id?: string;
  url: string;
}
export interface IMessage {
  id: string;
  contact_id: string;
  date: Date | string;
  data: Message | null;
  event_key?: 'no-more-message'
}

export class MySubClassedDexie extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  avatars!: Table<Avatar>;
  messages!: Table<IMessage>;

  constructor() {
    super('lulu');
    this.version(2).stores({
      avatars: '&contact_id, url',
      messages: '&id, contact_id',
    });
  }
}

export const db = new MySubClassedDexie();
