import { MESSAGE_LIMIT } from '@/configs';
import type { Message, MessageType } from '@/declarations/channel.d';
import { MessageStatus } from '@/declarations/channel.d';
import useModal from '@/hooks/useModal';
import type { IMessage } from '@/services/indexedDB/db';
import { deleteMessage, editMessage, getMessages, sendMessage } from '@/services/luluchat/channels';
import type { API } from '@/services/luluchat/typings';
import { Sound } from '@/utils/sound';
import { notification } from 'antd';
import moment from 'moment';
import { useRef, useState } from 'react';
import type { VirtuosoHandle } from 'react-virtuoso';
import { useModel, useRequest } from 'umi';
import { v4 as uuidv4 } from 'uuid';

const JUMP_TO_MESSAGE_TIMER = 1000;
const soundInstance = Sound.getInstance();
let jumpTimeOut: ReturnType<typeof setTimeout> = undefined;
export default () => {
  const listRef = useRef<VirtuosoHandle>(null);
  const { initialState } = useModel('@@initialState');
  const [currentHighlightMessage, setCurrentHighlightMessage] = useState<string | null>(null);
  const [currentEditMessage, setCurrentEditMessage] = useState<IMessage | null>(null);
  const [virtuosoComponentKey, setVirtuosoComponentKey] = useState<string>();
  const { run: fetchSendMesage, loading: isLoadingFetchSendMesage } = useRequest(sendMessage, {
    manual: true,
    formatResult(res) {
      if (initialState?.currentChannel?.type === 'waba') {
        soundInstance.playSendMessageAudio();
      }
      return res;
    },
  });

  const { run: fetchEditMessage, loading: isLoadingFetchEditMessage } = useRequest(editMessage, {
    manual: true,
    formatResult(res) {
      return res;
    },
  });
  const { run: fetchDeleteMessage, loading: isLoadingFetchDeleteMessage } = useRequest(
    deleteMessage,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );
  const [messages, setMessages] = useState<Message[]>([]);
  const [quoteMessage, setQuoteMessage] = useState<Message | null>(null);
  const [isForwardMessageEnabled, setIsForwardMessageEnabled] = useState<boolean>(false);
  const [forwardMessage, setForwardMessage] = useState<Message[]>([]);
  const [reactedMessages, setReactedMessages] = useState<Record<string, string>>({});
  const [isDisableLiveMessages, setIsDisableLiveMessages] = useState<boolean>(false);
  const [isNoMoreMessage, setIsNoMoreMessage] = useState(false);
  const [initialTopMostItemIndex, setInitialTopMostItemIndex] = useState<'LAST' | number>(0);
  const [isGoingToQuoteMessage, setIsGoingToQuoteMessage] = useState(false);
  const {
    isModalVisible: isEditMessageModalVisible,
    openModal: openEditMessageModal,
    closeModal: closeEditMessageModal,
  } = useModal();
  const {
    isModalVisible: isContactListModalVisible,
    openModal: openContactListModal,
    closeModal: closeContactListModal,
  } = useModal();

  const onDeleteMessage = async ({
    wa_contact_id,
    message_id,
  }: {
    wa_contact_id: string;
    message_id: string;
  }) => {
    if (!wa_contact_id || !message_id) return;
    if (quoteMessage?.id === message_id) {
      setQuoteMessage(null);
    }
    await fetchDeleteMessage({
      wa_contact_id,
      message_id,
    });
    setMessages((prevMessages) => {
      const nextMessages = [...prevMessages];
      const foundIndex = prevMessages.findIndex(({ id }) => message_id === id);
      if (foundIndex > -1) {
        nextMessages[foundIndex].type = 'deleted';
      }
      return nextMessages;
    });
  };

  const onReplyMessage = (message: Message) => {
    setQuoteMessage(message);
  };
  const clearQuoteMessage = () => {
    setQuoteMessage(null);
  };
  const searchMessage = () => {};

  const onForwardMessageComplete = () => {
    onResetForwardMessage();
    closeContactListModal();
  };

  const onResetForwardMessage = () => {
    setIsForwardMessageEnabled(false);
    setForwardMessage([]);
  };

  const onClickForwardButton = () => {
    openContactListModal();
  };

  const onSelectForwardMessage = (m: Message) => {
    setIsForwardMessageEnabled(true);
    setForwardMessage([m]);
  };

  const onClickForwardMessageCheckbox = (m: Message) => {
    setIsForwardMessageEnabled(true);
    setForwardMessage((prev) => {
      const next = [...prev];
      const foundIndex = next.findIndex((s) => s.id === m.id);
      if (foundIndex > -1) {
        next.splice(foundIndex, 1);
      } else {
        next.push(m);
      }
      //@ts-ignore-next-line
      next?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return next;
    });
  };

  const { run: fetchGetMessages, loading: isLoadingFetchGetMessages } = useRequest(getMessages, {
    manual: true,
    onSuccess(response, params) {
      if (response.status && response.data?.length > 0) {
        const _messages: Message[] = [];
        const _reactions: Message[] = [];
        response.data.forEach((message: Message) => {
          if (message.type !== 'reaction') {
            _messages.push(message);
          } else {
            _reactions.push(message);
          }
        });
        if (_reactions?.length > 0) {
          generateReactedMessages(_reactions);
        }
        if (params?.[0]?.is_skip_set_messages !== true) {
          if (params?.[0]?.search_mode === 'after') {
            setMessages((prev) => [...prev, ..._messages]);
          } else if (params?.[0]?.search_mode === 'before') {
            setMessages((prev) => [..._messages, ...prev]);
          } else {
            setMessages(_messages);
          }
        }
      }
    },
    formatResult(res) {
      return res;
    },
  });

  const onLoadMessages = async ({
    wa_contact_id,
    from_message_id,
  }: {
    wa_contact_id: string;
    from_message_id?: string;
  }) => {
    setIsNoMoreMessage(false);
    setMessages([]);
    const nextVirtuosoComponentKey = uuidv4();
    if (jumpTimeOut) {
      clearTimeout(jumpTimeOut);
    }
    if (from_message_id && wa_contact_id) {
      setCurrentHighlightMessage(from_message_id);
      setIsDisableLiveMessages(true);
      let _messages = [];
      const res = await fetchGetMessages({
        wa_contact_id,
        search_mode: 'start',
        search_id: from_message_id,
        limit: MESSAGE_LIMIT,
        is_skip_set_messages: true,
      });
      if (res.status) {
        _messages = res.data;
        setIsGoingToQuoteMessage(true);
        if (_messages.length < 10) {
          const res2 = await fetchGetMessages({
            wa_contact_id,
            search_mode: 'before',
            search_id: res?.meta?.first_id,
            limit: MESSAGE_LIMIT,
            is_skip_set_messages: true,
          });
          if (res2?.status) {
            _messages = [...res2?.data, ..._messages];
          } else {
            notification.error({
              message: 'Failed to retrieve messages',
              description: res?.message || 'Something wrong on loading messages...',
            });
          }

          setIsNoMoreMessage(res2?.data?.length === 0);

          // filter out reaction
          _messages = _messages.filter((message) => message.type !== 'reaction');
          console.log('onLoadMessages Less than 10', _messages);
          setMessages(_messages);

          // jump to index
          const foundIndex = _messages.findIndex(({ id }) => id === from_message_id);

          if (_messages?.length - foundIndex <= 5) {
            console.log('onLoadMessages LESS THAN 5', _messages?.length - foundIndex);
            setIsDisableLiveMessages(false);
          }
          const goToIndex = foundIndex > -1 ? (res2?.data?.length === 0 ? foundIndex + 1 : foundIndex) : 0;
          setInitialTopMostItemIndex(goToIndex);
          jumpTimeOut = setTimeout(() => {
            setIsGoingToQuoteMessage(false);
          }, JUMP_TO_MESSAGE_TIMER);
        } else {
          setIsNoMoreMessage(false);
          _messages = _messages.filter((message) => message.type !== 'reaction');
          setMessages(_messages);
          const foundIndex = _messages.findIndex(({ id }) => id === from_message_id);
          const goToIndex = foundIndex > -1 ? foundIndex : 0;
          setInitialTopMostItemIndex(goToIndex);
          console.log('onLoadMessages MORE than 10 messages', goToIndex);
          jumpTimeOut = setTimeout(() => {
            setIsGoingToQuoteMessage(false);
          }, JUMP_TO_MESSAGE_TIMER);
        }
      } else {
        notification.error({
          message: 'Failed to retrieve messages',
          description: res?.message || 'Something wrong on loading messages...',
        });
      }
      setVirtuosoComponentKey(nextVirtuosoComponentKey);
    } else {
      setInitialTopMostItemIndex('LAST');
      setCurrentHighlightMessage(null);
      setIsDisableLiveMessages(false);
      console.log('--fetchGetMessages 0--');
      const res = await fetchGetMessages({
        wa_contact_id,
        limit: MESSAGE_LIMIT,
      });
      if (res.status) {
        setIsNoMoreMessage(res.data.length === 0);
      } else {
        notification.error({
          message: 'Failed to retrieve messages',
          description: res?.message || 'Something wrong on loading messages...',
        });
      }
      console.log('--fetchGetMessages 2--');
      setVirtuosoComponentKey(nextVirtuosoComponentKey);
    }
  };

  const generateReactedMessages = (reactionMessages = [] as Message[]) => {
    if (reactionMessages && reactionMessages.length > 0) {
      const _reactedMessages: Record<string, string> = {};
      reactionMessages.forEach((data) => {
        _reactedMessages[data.quoted.messageId] = data.text;
      });
      setReactedMessages((prev) => {
        return { ..._reactedMessages, ...prev };
      });
    }
  };

  const onFetchSendMessage = async (m: Omit<API.SendMessageParams, 'random_id'>) => {
    const randomId = uuidv4();
    //@todo: remove this if in future
    if (initialState?.currentChannel?.type !== 'waba') {
      if (
        m?.type !== 'forward' &&
        m?.type !== 'template' &&
        m?.type !== 'note' &&
        m?.type !== 'flow' &&
        !m?.schedule_time
      ) {
        let payload: Message = {
          id: randomId,
          randomId,
          chatId: m?.number,
          from: {
            id: `${initialState?.currentChannel?.contact_number}@@s.whatsapp.net`,
            name: 'Me',
          },
          sentBy: `${initialState?.currentUser?.fullname}`,
          type: m?.type,
          isAbsent: false,
          fromMe: true,
          status: MessageStatus.PENDING,
          sortKey: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]-SS'),
          timestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        };
        if (m?.type === ('message' as MessageType)) {
          payload = {
            ...payload,
            type: 'text',
            text: m?.message,
          };
        }
        if (m?.type === ('note' as MessageType)) {
          payload = {
            ...payload,
            type: m?.type,
            text: m?.message,
          };
          delete payload?.status;
        }
        if (m?.type === ('reply' as MessageType)) {
          payload = {
            ...payload,
            type: 'text',
            text: m?.message,
            quoted: {
              from: m?.quoted?.from?.name,
              message: m?.quoted,
              messageId: m.message_id,
            },
          };
        }
        if (
          m?.type === ('image' as MessageType) ||
          m?.type === ('video' as MessageType) ||
          m?.type === ('audio' as MessageType)
        ) {
          payload = {
            ...payload,
            type: m?.type,
            metadata: {
              mimetype: m.mime,
              url: m.url,
            },
          };
        }
        if (m?.type === ('document' as MessageType)) {
          payload = {
            ...payload,
            type: m?.type,
            metadata: {
              filename: 'loading file...',
              mimetype: m.mime,
              url: m.url,
            },
          };
        }
        setMessages((prevMessages) => [...prevMessages, payload]);
      }
      if (localStorage.getItem('is-mute-inbox-sound') !== 'true') {
        soundInstance.playSendMessageAudio();
      }
    }
    const finalPayload = {
      ...m,
      random_id: randomId,
    };
    return await fetchSendMesage(finalPayload);
  };

  const goToLatestMessage = async (wa_contact_id: string) => {
    await onLoadMessages({
      wa_contact_id,
    });
  };

  return {
    listRef,
    currentHighlightMessage,
    isLoadingFetchDeleteMessage,
    fetchDeleteMessage,
    quoteMessage,
    messages,
    setMessages,
    reactedMessages,
    fetchGetMessages,
    isLoadingFetchGetMessages,
    onFetchSendMessage,
    fetchSendMesage,
    isLoadingFetchSendMesage,
    onDeleteMessage,
    onReplyMessage,
    clearQuoteMessage,
    onSelectForwardMessage,
    isForwardMessageEnabled,
    forwardMessage,
    searchMessage,
    isContactListModalVisible,
    closeContactListModal,
    onClickForwardButton,
    onResetForwardMessage,
    onForwardMessageComplete,
    onClickForwardMessageCheckbox,
    isDisableLiveMessages,
    goToLatestMessage,
    setIsDisableLiveMessages,
    onLoadMessages,
    isNoMoreMessage,
    setIsNoMoreMessage,
    isGoingToQuoteMessage,
    setCurrentHighlightMessage,
    setReactedMessages,
    isEditMessageModalVisible,
    openEditMessageModal,
    closeEditMessageModal,
    currentEditMessage,
    setCurrentEditMessage,
    virtuosoComponentKey,
    initialTopMostItemIndex,
    isLoadingFetchEditMessage,
    fetchEditMessage,
  };
};
